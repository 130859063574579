<template>
  <v-card>
    <v-card-title>
      <span class="headline">Kontrakt</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :error-messages="reg_dateErrors"
                  outlined
                  dense
                  :value="reg_date | dateFilter"
                  label="Shartnoma sanasi"
                  append-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @input="$v.reg_date.$touch()"
                  @blur="$v.reg_date.$touch()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reg_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :error-messages="reg_numberErrors"
              dense
              outlined
              label="Reg raqami"
              v-model="reg_number"
              @input="$v.reg_number.$touch()"
              @blur="$v.reg_number.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :error-messages="summaErrors"
              dense
              outlined
              label="Summa"
              v-model="summa"
              v-currency="options"
              @input="$v.summa.$touch()"
              @blur="$v.summa.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :error-messages="end_dateErrors"
                  dense
                  outlined
                  :value="end_date | dateFilter"
                  label="Tugash sanasi"
                  append-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @input="$v.end_date.$touch()"
                  @blur="$v.end_date.$touch()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="end_date"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
              :error-messages="countragentErrors"
              dense
              :items="contagetnList"
              item-text="name"
              item-value="id"
              label="Kontragent"
              outlined
              v-model="countragent"
              @input="$v.countragent.$touch()"
              @blur="$v.countragent.$touch()"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        :disabled="newContractLoading"
        @click="$emit('status')"
      >
        Bekor qilish
      </v-btn>
      <v-btn color="success" :disabled="newContractLoading" @click="save">
        <i v-if="newContractLoading" class="el-icon-loading"></i> Saqlash</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
export default {
  data: () => ({
    newContractLoading: false,
    menu2: false,
    menu1: false,
    countragent: '',
    reg_number: '',
    reg_date: '',
    end_date: '',
    summa: ''
  }),
  validations: {
    countragent: {
      required
    },
    summa: {
      required
    },
    reg_number: {
      required
    },
    reg_date: {
      required
    },
    end_date: {
      required
    }
  },
  beforeCreate() {
    this.$store.dispatch('getKontragentsContractsListWithoutPg')
  },
  computed: {
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    countragentErrors() {
      const errors = []
      if (!this.$v.countragent.$dirty) return errors
      !this.$v.countragent.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_numberErrors() {
      const errors = []
      if (!this.$v.reg_number.$dirty) return errors
      !this.$v.reg_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    summaErrors() {
      const errors = []
      if (!this.$v.summa.$dirty) return errors
      !this.$v.summa.required && errors.push("Summa shart bo'lgan qator")
      return errors
    },
    reg_dateErrors() {
      const errors = []
      if (!this.$v.reg_date.$dirty) return errors
      !this.$v.reg_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    end_dateErrors() {
      const errors = []
      if (!this.$v.end_date.$dirty) return errors
      !this.$v.end_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    contagetnList() {
      const data = this.$store.state.requests.kontragetnsWithoutPg
      return data
    }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          reg_number: this.reg_number,
          reg_date: this.reg_date,
          end_date: this.end_date,
          countragent: this.countragent,
          amount: parseFloat(this.summa.replace(/,/g, ''))
        }
        this.newContractLoading = true
        this.$store
          .dispatch('CreateContragentContract', data)
          .then(() => {
            this.newContractLoading = false
            this.reg_number = ''
            this.reg_date = ''
            this.summa = ''
            this.end_date = ''
            this.countragent = ''
            this.$emit('status')
            this.$v.$reset()
          })
          .catch((err) => {
            this.newContractLoading = false
            console.error(err)
          })
      }
    }
  }
}
</script>
