<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="card card-custom gutter-b example example-compact"
          v-if="filter"
        >
          <div class="card-header">
            <div
              class="
                row
                align-items-center
                col-md-12 col-lg-12 col-xl-12
                pb-5
                pt-5
              "
            >
              <div
                class="col-md-12 col-lg-12 col-xl-11"
                style="border: 1px solid transparent"
              >
                <div
                  class="row align-items-center"
                  style="border: 1px solid transparent"
                >
                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="reg_number__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Registratsiya raqami"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="countragent__full_name__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Kontragent"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-3 my-3 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="countragent__inn__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        :placeholder="$t('TABLE_HEADER.INN')"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 my-2 my-md-0">
                    <el-date-picker
                      v-model="reg_date"
                      type="date"
                      @change="fixRegDate"
                      format="yyyy-MM-dd"
                      :placeholder="$t('TABLE_HEADER.DATE')"
                    >
                    </el-date-picker>
                  </div>
                  <div class="col-md-2 my-1 my-md-0">
                    <el-date-picker
                      v-model="end_date"
                      type="date"
                      @change="fixEndDate"
                      format="yyyy-MM-dd"
                      :placeholder="$t('TABLE_HEADER.END_DATE')"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-xl-1 mt-5 mt-lg-0 text-right">
                <v-btn small @click="reset" color="error"
                  >{{ $t('BREADCRUMBS.CLEAN') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.ENTERPRICE.CONTRAGENT.CONTRACTS') }}
              </h3>
            </div>

            <div class="card-button">
              <v-btn color="warning mx-2" @click="filter = !filter">
                {{ $t('BREADCRUMBS.SEARCH') }}

                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn @click="dialog = true" class="ml-2" color="primary"
                >{{ $t('MENU.ENTERPRICE.CONTRAGENT.CONTRACTS') }} +</v-btn
              >
            </div>
            <!-- <v-row justify="center"> -->
            <v-dialog v-model="dialog" persistent max-width="600px">
              <Create v-on:status="dialog = false" />
            </v-dialog>
            <!-- </v-row> -->
          </div>

          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import ContractsAction from "@/view/content/dropdown/ContractsAction.vue";
import Create from './Create'

export default {
  components: { Create },
  data() {
    return {
      dialog: false,
      filter: false,
      reg_date: '', //conttype: '',
      end_date: '', //type: '',
      countragent__inn__icontains: '', //inn: '',
      countragent__full_name__icontains: '', //name: '',
      reg_number__icontains: '', //id: '',
      payTypes: [
        { name: 'Muddatli', value: 'MU' },
        { name: 'Muddati cheklanmagan', value: 'MS' },
        { name: "Summaga bog'liq", value: 'SU' }
      ],
      types: [
        { name: 'Kelishuv', value: 'NAQD' },
        { name: 'Shartnoma', value: 'CONT' }
      ]
    }
  },
  created() {
    this.$store.dispatch('getKontragentsContractsList')
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
        this.$store.state.requests.filterData.path.substring(
          0,
          this.$route.path.lastIndexOf('/')
        ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.filter = true
        this.reg_date = data.reg_date
        this.end_date = data.end_date
        this.countragent__inn__icontains = data.countragent__inn__icontains
        this.countragent__full_name__icontains =
          data.countragent__full_name__icontains
        this.reg_number__icontains = data.reg_number__icontains
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  methods: {
    fixRegDate(d) {
      if (d !== null) {
        let currentDate = new Date(d)
        let y = currentDate.getFullYear()
        let m = currentDate.getMonth() + 1
        let day = currentDate.getDate()
        this.reg_date = `${y}-${m}-${day}`
        this.myFn()
      } else {
        this.myFn()
        this.reg_date = ''
      }
    },
    fixEndDate(d) {
      if (d !== null) {
        let currentDate = new Date(d)
        let y = currentDate.getFullYear()
        let m = currentDate.getMonth() + 1
        let day = currentDate.getDate()
        this.end_date = `${y}-${m}-${day}`
        this.myFn()
      } else {
        this.myFn()
        this.end_date = ''
      }
    },
    reset() {
      this.reg_date = ''
      this.end_date = ''
      this.countragent__inn__icontains = ''
      this.countragent__full_name__icontains = ''
      this.reg_number__icontains = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      this.myFn()
    },
    myFn() {
      const data = {}
      console.log(this.reg_date, this.end_date)
      if (this.reg_date !== '' && this.reg_date !== null) {
        data.reg_date = this.reg_date
      }
      if (this.countragent__full_name__icontains !== '') {
        data.countragent__full_name__icontains =
          this.countragent__full_name__icontains
      }
      if (this.countragent__inn__icontains !== '') {
        data.countragent__inn__icontains = this.countragent__inn__icontains
      }
      if (this.end_date !== '' && this.end_date !== null) {
        data.end_date = this.end_date
      }
      if (this.reg_number__icontains !== '') {
        data.reg_number__icontains = this.reg_number__icontains
      }
      this.$store.commit('setFilterData', {
        data: data,
        path: this.$route.path
      })
      this.$store.dispatch('kontragentsContractsSearch', data)
      if (this.$route.path !== '/kontragents/contract/1') {
        this.$router.push('/kontragents/contract/' + '1')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.CONTRAGENT.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.CONTRAGENT.CONTRACTS') }
    ])
  }
}
</script>
